var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"block_box"},[_c('div',{staticClass:"main"},[_c('div',{staticClass:"top pull-left"},[_c('div',{staticClass:"left-content pull-left padding10"},[_c('svg',{staticClass:"icon quotationImg pull-left",attrs:{"aria-hidden":"true"}},[_c('use',{attrs:{"href":"#icon-fenkuaidingjia"}})]),_c('div',[_c('p',{staticClass:"quotationCode"},[_vm._v(_vm._s(_vm.productname))]),_c('p',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t("label.block.pricing.editor")))])])])]),_c('div',{staticClass:"edit_box"},[_c('el-form',{ref:"blockForm",staticClass:"book_form demo-ruleForm mar-t-20",attrs:{"model":_vm.blockForm}},[_c('h4',[_vm._v(_vm._s(_vm.$t("label.block.pricing")))]),_c('el-form-item',{attrs:{"label":_vm.$t('lable.opppdt.selPbk.pricebook')}},[_c('el-select',{on:{"change":_vm.selectBtn},model:{value:(_vm.blockForm.pricebookid),callback:function ($$v) {_vm.$set(_vm.blockForm, "pricebookid", $$v)},expression:"blockForm.pricebookid"}},_vm._l((_vm.pricebooks),function(val){return _c('el-option',{key:val.id,attrs:{"label":val.name,"value":val.id}})}),1)],1),_c('el-table',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticClass:"book_table",staticStyle:{"width":"100%"},attrs:{"data":_vm.blockForm.copyData,"header-cell-style":{
            background: '#F5F5F5',
            color: '#3E3E3C',
            fontWeight: 400,
          },"height":_vm.bookHeight,"border":""}},[_c('el-table-column',{attrs:{"type":"index","width":"78"}}),_vm._l((_vm.tableAttr),function(item){return [_c('el-table-column',{key:item.prop,attrs:{"label":item.label},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-form-item',{attrs:{"prop":'copyData.' + scope.$index + "." + (item.prop)}},[(item.fieldtype === 'S')?_c('el-input',{attrs:{"size":"small","maxlength":item.schemefieldLength === null ||
                      item.schemefieldLength === undefined
                        ? 1000
                        : Number(item.schemefieldLength),"auto-complete":"off"},model:{value:(scope.row[item.apiname]),callback:function ($$v) {_vm.$set(scope.row, item.apiname, $$v)},expression:"scope.row[item.apiname]"}}):_vm._e(),(item.fieldtype === 'N' || item.fieldtype === 'c')?_c('el-input-number',{attrs:{"controls":false,"min":item.schemefieldLength !== undefined &&
                      item.schemefieldLength !== null
                        ? 0 - Math.pow(10, Number(item.schemefieldLength)) + 1
                        : _vm.min,"max":item.schemefieldLength !== undefined &&
                      item.schemefieldLength !== null
                        ? Math.pow(10, Number(item.schemefieldLength)) - 1
                        : _vm.max,"precision":item.decimalPlaces !== undefined &&
                      item.decimalPlaces !== null
                        ? Number(item.decimalPlaces)
                        : _vm.precision},model:{value:(scope.row[item.apiname]),callback:function ($$v) {_vm.$set(scope.row, item.apiname, $$v)},expression:"scope.row[item.apiname]"}}):_vm._e()],1)]}}],null,true)})]}),_c('el-table-column',{attrs:{"label":"","width":"108"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('div',{staticClass:"btn_all"},[_c('el-button',{staticClass:"btn_s",attrs:{"size":"mini"},on:{"click":function($event){return _vm.delShopList(scope.$index, scope.row)}}},[_vm._v("-")]),(_vm.blockForm.copyData.length - 1 === scope.$index)?_c('el-button',{staticClass:"btn_s",attrs:{"size":"mini"},on:{"click":function($event){return _vm.addShopList(scope.row)}}},[_vm._v("+")]):_vm._e()],1)]}}])})],2)],1),_c('div',{staticClass:"dialog-footer"},[_c('el-button',{attrs:{"size":"small"},on:{"click":_vm.blockClose}},[_vm._v(" "+_vm._s(_vm.$t("label.ems.cancel"))+" ")]),_c('el-button',{attrs:{"size":"small"},on:{"click":function($event){return _vm.blockSubmit('fast')}}},[_vm._v(" "+_vm._s(_vm.$t("setup.layout.button.quicksave")))]),_c('el-button',{attrs:{"type":"primary","size":"small"},on:{"click":function($event){return _vm.blockSubmit('save')}}},[_vm._v(" "+_vm._s(_vm.$t("component.telerecord.button.save")))])],1)],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }