<!-- 分块定价 -->
<!-- 开发者：王雪茹 -->
<template>
  <div class="block_box">
    <div class="main">
      <!-- 页头标题 -->
      <div class="top pull-left">
        <div class="left-content pull-left padding10">
          <svg class="icon quotationImg pull-left" aria-hidden="true">
            <use href="#icon-fenkuaidingjia"></use>
          </svg>
          <div>
            <p class="quotationCode">{{ productname }}</p>
            <!-- 分块定价编辑器 -->
            <p class="title">{{ $t("label.block.pricing.editor") }}</p>
          </div>
        </div>
      </div>
      <div class="edit_box">
        <!-- 表格部分 -->
        <el-form
          :model="blockForm"
          ref="blockForm"
          class="book_form demo-ruleForm mar-t-20"
        >
          <!-- 分块定价 -->
          <h4>{{ $t("label.block.pricing") }}</h4>
          <!-- 价格手册 -->
          <el-form-item :label="$t('lable.opppdt.selPbk.pricebook')">
            <el-select v-model="blockForm.pricebookid" @change="selectBtn">
              <el-option
                v-for="val in pricebooks"
                :key="val.id"
                :label="val.name"
                :value="val.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-table
            :data="blockForm.copyData"
            style="width: 100%"
            :header-cell-style="{
              background: '#F5F5F5',
              color: '#3E3E3C',
              fontWeight: 400,
            }"
            class="book_table"
            :height="bookHeight"
            border
            v-loading="loading"
          >
            <el-table-column type="index" width="78"></el-table-column>
            <template v-for="item in tableAttr">
              <el-table-column :label="item.label" :key="item.prop">
                <template slot-scope="scope">
                  <el-form-item
                    :prop="'copyData.' + scope.$index + `.${item.prop}`"
                  >
                    <!-- 普通类型字段 -->
                    <el-input
                      v-if="item.fieldtype === 'S'"
                      v-model="scope.row[item.apiname]"
                      size="small"
                      :maxlength="
                        item.schemefieldLength === null ||
                        item.schemefieldLength === undefined
                          ? 1000
                          : Number(item.schemefieldLength)
                      "
                      auto-complete="off"
                    ></el-input>
                    <!-- 数字类型字段 -->
                    <el-input-number
                      v-if="item.fieldtype === 'N' || item.fieldtype === 'c'"
                      v-model="scope.row[item.apiname]"
                      :controls="false"
                      :min="
                        item.schemefieldLength !== undefined &&
                        item.schemefieldLength !== null
                          ? 0 - Math.pow(10, Number(item.schemefieldLength)) + 1
                          : min
                      "
                      :max="
                        item.schemefieldLength !== undefined &&
                        item.schemefieldLength !== null
                          ? Math.pow(10, Number(item.schemefieldLength)) - 1
                          : max
                      "
                      :precision="
                        item.decimalPlaces !== undefined &&
                        item.decimalPlaces !== null
                          ? Number(item.decimalPlaces)
                          : precision
                      "
                    ></el-input-number>
                  </el-form-item>
                </template>
              </el-table-column>
            </template>
            <!-- 操作 -->
            <el-table-column label="" width="108">
              <template slot-scope="scope">
                <div class="btn_all">
                  <el-button
                    @click="delShopList(scope.$index, scope.row)"
                    size="mini"
                    class="btn_s"
                    >-</el-button
                  >
                  <el-button
                    @click="addShopList(scope.row)"
                    size="mini"
                    v-if="blockForm.copyData.length - 1 === scope.$index"
                    class="btn_s"
                    >+</el-button
                  >
                </div>
              </template>
            </el-table-column>
          </el-table>
        </el-form>
        <!-- 底部按钮 -->
        <div class="dialog-footer">
          <el-button @click="blockClose" size="small">
            <!-- 取消 -->
            {{ $t("label.ems.cancel") }}
          </el-button>
          <el-button size="small" @click="blockSubmit('fast')">
            <!-- 快速保存 -->
            {{ $t("setup.layout.button.quicksave") }}</el-button
          >
          <el-button type="primary" @click="blockSubmit('save')" size="small">
            <!-- 保存 -->
            {{ $t("component.telerecord.button.save") }}</el-button
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as quotationApi from "./api.js";

export default {
  name: "blockPricing",
  components: {},
  data() {
    return {
      bookHeight: "300px",
      pricebooks: [],
      // tableAttr: [
      //   {
      //     label: this.$i18n.t('label.emailtocloudcc.name'), // 名称
      //     type: 'input',
      //     prop: 'name',
      //     editable: false,
      //   },
      //   {
      //     label: this.$i18n.t('label.lower.bound'), // 下界
      //     prop: 'lowerbound',
      //     type: 'number',
      //     editable: false,
      //     interval: {
      //       min: 1,
      //       max: 999999,
      //     },
      //     precision: 0,
      //   },
      //   {
      //     label: this.$i18n.t('label.upper.bound'), // 上界
      //     prop: 'upperbound',
      //     type: 'number',
      //     editable: false,
      //     interval: {
      //       min: 1,
      //       max: 999999,
      //     },
      //     precision: 0,
      //   },
      //   {
      //     label: '价格（'+this.$store.getters.getCurrencyCode+'）',//价格（元）
      //     prop: 'price',
      //     type: 'number',
      //     editable: false,
      //     interval: {
      //       min: 0,
      //       max: 999999,
      //     },
      //     precision: 2,
      //   },
      // ],
      blockForm: {
        pricebookid: "",
        etyyStr: [
          {
            name: "",
            lowerbound: 1,
            upperbound: 1,
            price: 0,
            currency: "",
          },
        ],
        copyData: [],
      },
      initData: {
        name: "",
        lowerbound: 1,
        upperbound: 1,
        price: 0,
        currency: "",
      },
      productid: this.$route.query.id, //产品id
      // isSaveflag: false,
      productname: "",
      loading: false,
      // isIdentical:false,//判断数组是否相同
      oldPricebookid: "", //切换前的价格手册id
      isDele: false, //判断是否是操作了删除减号按钮
      min: 0 - Math.pow(10, 18) + 1,
      max: Math.pow(10, 18) - 1,
      precision: 2,
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.bookHeight =
        document.getElementsByClassName("block_box")[0].clientHeight -
        229 +
        "px"; //减去头部高度
    });
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      //获取价格手册
      quotationApi.getPriceBook().then((res) => {
        if (res.data) {
          this.pricebooks = res.data;
          this.blockForm.pricebookid = res.data[0].id;
          this.showBlockPrice();
        }
      });
    },
    //选择价格手册切换
    selectBtn() {
      //'如需保存当前页面信息，请点击“快速保存”按钮。'
      //提示
      //确定
      this.isDele = false;
      let isRules = false;
      let etyyStr = this.blockForm.etyyStr,
        copyData = this.blockForm.copyData;
      if (etyyStr.length === copyData.length) {
        for (let key in copyData) {
          copyData[key].isFlag =
            (copyData[key].name === "" ? null : copyData[key].name) ==
              (etyyStr[key].name === "" ? null : etyyStr[key].name) &&
            (copyData[key].lowerbound === ""
              ? null
              : copyData[key].lowerbound) ==
              (etyyStr[key].lowerbound === ""
                ? null
                : etyyStr[key].lowerbound) &&
            (copyData[key].upperbound === ""
              ? null
              : copyData[key].upperbound) ==
              (etyyStr[key].upperbound === ""
                ? null
                : etyyStr[key].upperbound) &&
            (copyData[key].price === "" ? null : copyData[key].price) ==
              (etyyStr[key].price === "" ? null : etyyStr[key].price);
        }
        isRules = copyData.some((val) => {
          return !val.isFlag;
        });
        if (isRules) {
          // label.save.the.block.pricing.information.of.the.current.price.manual
          this.$confirm(
            "是否保存当前价格手册的分块定价信息？",
            this.$i18n.t("label.emailtocloudcc.tip"),
            {
              confirmButtonText: this.$i18n.t("label.confirm"), //'确定',
              cancelButtonText: this.$i18n.t("label.ems.cancel"), //'取消',
              type: "warning",
            }
          )
            .then(() => {
              this.blockForm.pricebookid = this.oldPricebookid;
              this.blockSubmit("fast");
            })
            .catch(() => {
              this.showBlockPrice();
            });
        } else {
          this.showBlockPrice();
        }
      } else {
        // 提示
        this.$confirm(
          "是否保存当前价格手册的分块定价信息？",
          this.$i18n.t("label.emailtocloudcc.tip"),
          {
            confirmButtonText: this.$i18n.t("label.confirm"), //'确定',
            cancelButtonText: this.$i18n.t("label.ems.cancel"), //'取消',
            type: "warning",
          }
        )
          .then(() => {
            this.blockForm.pricebookid = this.oldPricebookid;
            this.blockSubmit("fast");
          })
          .catch(() => {
            this.showBlockPrice();
          });
      }
    },
    //分块定价列表
    showBlockPrice() {
      // 处理表头
      // this.tableAttr
      this.loading = true;
      let data = {
        productid: this.productid,
        pricebookid: this.blockForm.pricebookid,
      };
      //展示分块价格
      quotationApi.showBlockPrice(data).then((res) => {
        if (res.data) {
          res.data.orderTableFields.forEach((val) => {
            if (val.fieldtype === "c") {
              this.$set(
                val,
                "label",
                val.label + this.$store.getters.getCurrencyCode
              );
            }
          });
          this.tableAttr = res.data.orderTableFields;
          if (res.data.blockpricelist.length > 0) {
            res.data.blockpricelist.forEach((val) => {
              if (val.upperbound === null) {
                val.upperbound = undefined;
              }
              if (val.lowerbound === null) {
                val.lowerbound = undefined;
              }
            });
            this.blockForm.etyyStr = res.data.blockpricelist;
          } else {
            //判断是否是操作了删除减号按钮
            if (!this.isDele) {
              this.blockForm.etyyStr = [
                {
                  id: "",
                  name: "",
                  lowerbound: 1,
                  upperbound: 1,
                  price: 0,
                  currency: "",
                },
              ];
            } else {
              this.blockForm.etyyStr = [];
            }
          }
          this.blockForm.copyData = JSON.parse(
            JSON.stringify(this.blockForm.etyyStr)
          );
          this.productname = res.data.productname;
          this.loading = false;
        }
      });
    },
    //加
    addShopList(row) {
      this.initData.xj = row.sj;
      this.blockForm.copyData.push({
        ...this.initData,
        id: "",
      });
    },
    //减
    delShopList(id, row) {
      if (row.id) {
        quotationApi
          .deleteData({ id: row.id, type: "blockprice" })
          .then((res) => {
            if (res.result) {
              this.$message({
                showClose: true,
                message: this.$i18n.t("label.ems.delete.success"),
                type: "success",
              }); //删除成功
              this.isDele = true;
              this.showBlockPrice();
            }
          });
      } else {
        this.isDele = false;
        this.blockForm.copyData.splice(id, 1);
      }
    },
    //取消
    blockClose() {
      this.$router.go(-1);
    },
    //快速保存、保存
    blockSubmit(fastVal) {
      //上界必须大于下届
      //每行下界必须等于上一行上届
      //最后一行和第一行上界可以为空
      // return false
      let judgeFlag = true;
      let isSave = false;
      let etyyStrLength = this.blockForm.copyData.length;
      this.blockForm.copyData.forEach((val, index) => {
        this.blockForm.copyData[index].isSaveflag = false;
        //每行下界为必填项
        if (val.name !== undefined && val.name !== null && val.name !== "") {
          if (val.lowerbound !== undefined) {
            //上界必须大于下届（除第一行和最后一行外）
            if (
              val.lowerbound < val.upperbound &&
              index !== 0 &&
              index !== etyyStrLength - 1
            ) {
              //每行下界必须等于上一行上届
              if (
                this.blockForm.copyData[index].lowerbound ===
                this.blockForm.copyData[index - 1].upperbound
              ) {
                //如果为true表示校验完成，可保存
                this.blockForm.copyData[index].isSaveflag = true;
                this.blockForm.copyData[0].isSaveflag = true;
              } else {
                this.blockForm.copyData[index].isSaveflag = false;
                //'分块定价每1层的下界必须等于上1层的上界。'
                this.$message.error(
                  this.$i18n.t(
                    "label.the.lower.bound.of.each.level.must.be.equal.to.the.upper.bound.of.the.upper.level."
                  )
                );
                judgeFlag = false;
              }
            } else if (index === etyyStrLength - 1 && index !== 0) {
              // 最后一行的判断
              if (
                this.blockForm.copyData[index].lowerbound ===
                this.blockForm.copyData[index - 1].upperbound
              ) {
                //最后一行为空或是比下界大，可保存
                if (
                  this.blockForm.copyData[index].upperbound === undefined ||
                  this.blockForm.copyData[index].lowerbound <
                    this.blockForm.copyData[index].upperbound
                ) {
                  //如果为true表示校验完成，可保存
                  this.blockForm.copyData[index].isSaveflag = true;
                  //判断上界是否有值
                  if (this.blockForm.copyData[index].upperbound === undefined) {
                    this.blockForm.copyData[
                      this.blockForm.copyData.length - 1
                    ].upperbound = undefined;
                  } else {
                    this.blockForm.copyData[
                      this.blockForm.copyData.length - 1
                    ].upperbound =
                      this.blockForm.copyData[
                        this.blockForm.copyData.length - 1
                      ].upperbound;
                  }
                } else {
                  this.blockForm.copyData[index].isSaveflag = false;
                  //分块定价每1层的上界必须大于下界。
                  this.$message.error(
                    this.$i18n.t(
                      "label.the.upper.bound.of.each.level.of.block.pricing.must.be.greater.than.the.lower.bound."
                    )
                  );
                  judgeFlag = false;
                }
              } else {
                this.blockForm.copyData[index].isSaveflag = false;
                //'分块定价每1层的下界必须等于上1层的上界。'
                this.$message.error(
                  this.$i18n.t(
                    "label.the.lower.bound.of.each.level.must.be.equal.to.the.upper.bound.of.the.upper.level."
                  )
                );
                judgeFlag = false;
              }
            } else if (index === 0) {
              // 第一行的判断
              //第一行为空或是比下界大，可保存
              if (
                this.blockForm.copyData[index].upperbound === undefined ||
                this.blockForm.copyData[index].lowerbound <
                  this.blockForm.copyData[index].upperbound
              ) {
                //如果为true表示校验完成，可保存
                this.blockForm.copyData[index].isSaveflag = true;
                //判断上界是否有值
                if (this.blockForm.copyData[index].upperbound === undefined) {
                  this.blockForm.copyData[index].upperbound = undefined;
                }
              } else {
                this.blockForm.copyData[index].isSaveflag = false;
                //分块定价每1层的上界必须大于下界。
                this.$message.error(
                  this.$i18n.t(
                    "label.the.upper.bound.of.each.level.of.block.pricing.must.be.greater.than.the.lower.bound."
                  )
                );
                judgeFlag = false;
              }
            } else {
              //分块定价每1层的上界必须大于下界。
              this.$message.error(
                this.$i18n.t(
                  "label.the.upper.bound.of.each.level.of.block.pricing.must.be.greater.than.the.lower.bound."
                )
              );
              judgeFlag = false;
            }
          } else {
            //分块定价每1层的下界为必填项。
            this.$message.error(
              this.$i18n.t(
                "label.the.lower.bound.of.each.level.of.block.pricing.is.required."
              )
            );
            judgeFlag = false;
          }
        } else {
          //名称为必填项。
          this.$message.error(this.$i18n.t("label.name.required"));
          judgeFlag = false;
        }
      });
      if (judgeFlag) {
        isSave = this.blockForm.copyData.every((val) => {
          return val.isSaveflag;
        });
        if (isSave) {
          let copyDataArr = JSON.parse(JSON.stringify(this.blockForm.copyData));
          copyDataArr.forEach((val) => {
            val.pricebook = this.blockForm.pricebookid;
            val.product = this.productid;
            val.quoteline = "";
            val.lowerbound = val.lowerbound.toString();
            val.upperbound = val.upperbound ? val.upperbound.toString() : "";
            val.price = val.price.toString();
            if (val.id === "") {
              delete val.id;
            }
          });
          quotationApi
            .saveBlockPrice({ blockpriceList: copyDataArr })
            .then((res) => {
              if (res.result) {
                //fastVal为fast是快速保存、save是保存
                if (fastVal === "save") {
                  this.$router.go(-1);
                }
                if (fastVal === "fast") {
                  this.$message({
                    showClose: true,
                    message: this.$i18n.t("savesuccess"),
                    type: "success",
                  });
                  this.showBlockPrice();
                }
              }
            });
        }
      }
    },
  },
  watch: {
    "blockForm.pricebookid"(nval, val) {
      this.oldPricebookid = val;
    },
  },
};
</script>

<style lang="scss" scoped>
p {
  margin-bottom: 0;
}
.padding10 {
  padding: 10px;
}
.block_box {
  border-left: none;
  border-right: none;
  border-radius: 3px;
  position: relative;
  height: 100%;
  padding: 10px 10px 10px 10px;

  .main {
    border-radius: 3px;
    border: 1px solid #dedcda;
    background: #fff;
    height: auto;

    .top {
      width: 100%;
      height: 56px;
      background: #f5f5f5;
      border-bottom: 1px solid #dedcda;

      .left-content {
        width: 30%;

        .quotationImg {
          width: 36px;
          height: 36px;
          margin-right: 10px;
        }

        .quotationCode {
          color: #333;
          font-size: 12px;
          height: 16px;
          line-height: 16px;
        }

        .title {
          color: #080707;
          font-size: 16px;
          font-weight: bold;
        }
      }
    }
    .edit_box {
      width: 100%;
      .book_form {
        margin-top: 50px;
        h4 {
          padding-top: 15px;
          padding-left: 10px;
          box-sizing: border-box;
          margin-bottom: 0;
          font-size: 14px;
          color: #080707;
        }
        .el-form-item :first-child {
          padding-left: 10px;
          box-sizing: border-box;
          .el-form-item__label {
            padding: 0;
          }
        }
        ::v-deep .el-form-item__content {
          padding-left: 0 !important;
          .el-input-number {
            padding-left: 0 !important;
            .el-input {
              padding-left: 0 !important;
            }
          }
          .el-input {
            padding-left: 0 !important;
          }
        }
        ::v-deep .el-input__inner {
          width: 178px;
          height: 30px;
        }
        ::v-deep .el-form-item {
          margin-bottom: 10px;
        }
      }
      .book_table {
        border-top: 1px solid #dedcda;
        ::v-deep th {
          padding: 8px 0;
          border-bottom: 1px solid #dedcda;
          border-right: 1px solid #dedcda;
        }
        ::v-deep td {
          padding: 0;
          border-bottom: 1px solid #dedcda;
          border-right: 0;
          .el-input--small .el-input__inner {
            width: 80%;
            border: 1px solid #888888;
            height: 26px;
            line-height: 26px;
          }
          .el-form-item,
          .el-form-item--mini.el-form-item,
          .el-form-item--small.el-form-item {
            margin-bottom: 0 !important;
          }
          .el-input-number {
            width: 80%;
            .el-input {
              width: 100%;
              input {
                width: 100%;
                text-align: left;
                height: 26px;
                border: 1px solid #888888;
              }
            }
          }
        }
        ::v-deep td:first-child {
          .cell {
            div {
              margin-top: 12px;
            }
          }
        }
        ::v-deep td:last-child {
          .cell {
            display: flex;
            align-items: center;
            .btn_all {
              display: flex;
              .btn_s {
                width: 20px;
                height: 20px;
                padding: 0;
                border: 1px solid #dddbda;
                ::v-deep span {
                  font-size: 20px;
                  width: 20px;
                  height: 20px;
                  display: inline-block;
                  margin-top: -3px;
                  margin-left: -1px;
                  color: #888888;
                }
              }
            }
          }
        }
      }
      .dialog-footer {
        width: 100%;
        margin: 20px 0;
        text-align: center;
      }
    }
  }
}
</style>
